$red: #ec1738;
$orange: #e39737;
$yellow: #ffc107;
$green: #17b737;
$teal: #20c997;
$cyan: #17a2b8;
$blue: #007bff;
$white: #fff;
$gray: #60696f;
$gray-dark: #30393f;
$primary: #17a7b7;
$secondary: #577783;
$success: #77c717;
$info: #177797;
$warning: #e3a303;
$danger: #ec1737;
$light: #90999f;
$dark: #30393f;
$black: #000;
