/* You can add global styles to this file, and also import other style files */
// See https://developer.telerik.com/products/kendo-ui/using-kendo-ui-with-angular-2/
// $icon-font-path: "bootstrap-sass/assets/fonts/bootstrap/";

// Override bootstrap lg breakpoint
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1023px,
  xl: 1200px
);

// Bootstrap
@import './assets/stylesheets/bootstrap-color-overrides';
@import 'bootstrap/scss/bootstrap';

// App styles
@import './assets/stylesheets/colors';
@import './assets/stylesheets/html-generic';

// Kendo
@import './assets/kendo-theme/index';

// Other
@import './assets/stylesheets/breadcrumb';
@import './assets/stylesheets/awesome-buttons';
@import './assets/stylesheets/kendo-grid-search';

// Fonts
@import './assets/stylesheets/fonts';

.form-text {
  text-align: center;
  padding: 0.5rem;
}

.form-btn {
  align-self: center;
  padding: 0 1rem;
}

.p-relative {
  position: relative;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.k-dialog {
  max-height: 90%;
}

a{
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
