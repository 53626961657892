.align-content-center {
  text-align: center !important;
}

@mixin awesomeBtn($awBtnClr) {
  width: 3.5rem !important;
  background-image: none !important;
  margin: 6px !important;
  line-height: 1rem !important;

  @if ($awBtnClr != '') {
    background-color: $awBtnClr !important;
    border-color: $awBtnClr !important;
  }

  .k-button-text {
    display: inherit;
  }

  &.k-button:hover::before {
    opacity: 0.7;
    color: $white !important;
  }
}

.awesome-btn-default {
  @include awesomeBtn('');
}

.awesome-btn-green {
  @include awesomeBtn($green);
}

.awesome-btn-red {
  @include awesomeBtn($red);
}

.awesome-btn-orange {
  @include awesomeBtn($orange);
}

.awesome-btn-auto-margin {
  margin: auto !important;
}

fa-icon {
  font-size: 1.1rem;
}

.text-btn-green {
  background-color: $green !important;
}
