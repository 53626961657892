@font-face {
  font-family: OpenSans;
  src: url('../webfonts/OpenSans-Regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans|semibold';
  src: url('../webfonts/OpenSans-Semibold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

kendo-pdf-export {
  font-family: OpenSans, Arial, sans-serif;
  font-size: 12px;
}
