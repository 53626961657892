// Compatible with @progress/kendo-theme-default v.8.0.0
@use '../stylesheets/bootstrap-color-overrides' as colors;

.k-switch.k-switch-off .k-switch-track {
  background-color: k-color(error);
  background-image: none;

}

.k-switch.k-switch-on .k-switch-track {
  background-color: k-color(primary);
  background-image: none;

}

.k-grid.k-grid-md .k-table-thead .k-table-row .k-header.k-table-th, .k-grid.k-grid-md .k-grid-header .k-table-row .k-header.k-table-th {
  background-color: k-color(base-on-subtle);
  background-image: none;
  color: k-color(app-surface);

}

// dialog
.k-dialog-wrapper .k-window.k-dialog .k-window-titlebar.k-dialog-titlebar{
  background-color: k-color(primary);
  background-image: none;
  color: k-color(on-primary);
}

.k-button.k-rounded-md {
  border-bottom-left-radius: $tb-border-radius;
  border-bottom-right-radius: $tb-border-radius;
  border-top-left-radius: $tb-border-radius;
  border-top-right-radius: $tb-border-radius;

}

.k-chip.k-rounded-md {
  border-bottom-left-radius: $tb-border-radius;
  border-bottom-right-radius: $tb-border-radius;
  border-top-left-radius: $tb-border-radius;
  border-top-right-radius: $tb-border-radius;
}

kendo-dialog {
  .k-window {
    max-width: 90%;
  }
}

.k-dialog-wrapper .k-window.k-dialog .k-window-actions.k-dialog-actions.k-actions.k-actions-stretched{
  flex-direction: row;
  padding: 0;
  gap: 0;

  button {
    height: 3rem;
  }

  button:not([themecolor='primary']) {
    background-color: colors.$orange !important;
    border-color: colors.$orange !important;
    color: colors.$white !important;

    &:hover,
    &:focus-visible {
      background-color: colors.$white !important;
      color: colors.$orange !important;
    }
  }
}
