// Compatible with @progress/kendo-theme-default v.8.0.0

$tb-border-radius: 0px;
$tb-primary: #17a7b7;
$tb-primary-hover: #17a7b7;
$tb-error: #ec1737;
$tb-primary-active: #17a7b7;
$tb-app-surface: #ffffff;
$tb-success: #77c717;
$tb-info: #177797;
$tb-base-on-subtle: #3d3d3d;
$tb-primary-emphasis: #17a7b7;
$tb-primary-on-subtle: #17a7b7;
$tb-secondary: #577783;
$tb-warning: #e3a303;


    $kendo-font-family: inherit;
    $kendo-font-size: 14px;
    $kendo-font-weight-normal: 400;
    $kendo-line-height: 1.4285714286;
    
$kendo-colors: (
primary: #17a7b7,
primary-hover: #17a7b7,
error: #ec1737,
primary-active: #17a7b7,
success: #77c717,
info: #177797,
primary-emphasis: #17a7b7,
primary-on-subtle: #17a7b7,
secondary: #577783,
warning: #e3a303,
);
$enable-gradients: true;

$tb-typography: (
  kendo-default-typography: (
      font-family: 'inherit',
      font-size: 14px,
      font-weight: 400,
      line-height: 1.4285714286,
        ),
);

@mixin typography-classes ($typography) {
  @each $selector, $property in $typography {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: #{$propValue};
      }
    } &-#{$selector}-important {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue !important;
      }
    }
  }
}

$tb-effects: (
  tb-internal-none-effects: (
      box-shadow: (none), filter: (none), backdrop-filter: (none),  ),
);

@mixin effects-classes ($effects) {
  @each $selector, $property in $effects {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue;
      }
    } &-#{$selector}-important {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue !important;
      }
    }
  }
}

%tb-typography {
  @include typography-classes($tb-typography);
}

%tb-effects {
  @include effects-classes($tb-effects);
}