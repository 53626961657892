html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
  font-family: OpenSans, sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: $dark;

  .light-italic-table tr.provisional {
    font-style: italic;
    color: $light;
  }
}

h1 {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 1.7rem 0 -0.7rem;
}

h2,
h3,
h4 {
  color: inherit;
  font-size: 1rem;
  font-weight: 600;
}

.pictogram {
  width: 1.7rem;
  margin: -0.3rem 0 0 0.3rem;
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.r-seperator {
  border-right: 1px solid #577783;
}

hr {
  border-bottom: 0.5px solid #577783;
}

label {
  word-wrap: break-word;
  font-weight: 500;
}

fieldset {
  border-width: 0 !important;
}

// The right side of a 2-column-layout
.col-right {
  padding: 0;
}

a {
  &.row-link {
    font-weight: bold !important;
    cursor: pointer !important;
    color: $primary !important;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  header .container {
    max-width: 768px !important;
  }
}

@media (min-width: 991px) {
  header .container {
    min-width: 95% !important;
  }
}
