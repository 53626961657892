@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/_variables.scss';
@import '/src/assets/stylesheets/bootstrap-color-overrides.scss';

.breadcrumbs__container {
  padding: 0;
  margin-top: -20px;
}

.breadcrumbs__item {
  display: inline-block;
  font-size: 0.8em;
  list-style-type: none;
  border-radius: 0 !important;

  a {
    color: $black;
  }

  &::after {
    content: '/';
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &:last-of-type::after {
    content: '';
  }
}
